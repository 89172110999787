import React, { FC } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { graphql, useStaticQuery } from 'gatsby';

import Layout from 'layout/Layout';
import PageDescription from 'components/PageDescription';
import SimpleBanner from 'components/SimpleBanner';
import SimpleSearch from 'components/SimpleSearch';
import Teaser from 'components/Teaser';
import TextBox from 'components/TextBox';
import createBreadcrumbs from 'utils/createBreadcrumbs';

const NotFoundPage: FC<{ location: { pathname: string } }> = ({ location: { pathname } }) => {
  const {
    allNotFoundPage: { nodes: notFoundPageNodes },
    languageSelector: { languages },
    allSitePage: { nodes },
  } = useStaticQuery(graphql`
    query {
      allNotFoundPage {
        nodes {
          seo {
            ...SEOStructureFragment
          }
          langProps {
            urls {
              ...languageUrls
            }
            lang
          }
          pageCrumbName
          mainBanner {
            background
            description
            title
          }
          searchTitle
          pageDescription {
            description
            backgroundColor
          }
          textbox {
            text
            title
          }
          bottomTeaser {
            ...TeaserFragment
          }
        }
      }
      languageSelector {
        defaultLang
        languages {
          iso
          path
        }
      }
      allSitePage {
        ...FragmentAllPageSite
      }
    }
  `);

  const sortedLanguages = [...languages].sort(
    (lang1, lang2) => lang2.path.length - lang1.path.length
  );
  const actualLang = sortedLanguages.find(({ path }) => pathname.startsWith(path));

  const {
    seo,
    langProps: { urls },
    pageCrumbName,
    mainBanner: [mainBanner],
    searchTitle,
    pageDescription,
    textbox: [textbox],
    bottomTeaser: [bottomTeaser],
  } = notFoundPageNodes.find(({ langProps: { lang } }) => lang === actualLang.iso)!;

  // hardcoded breadcrumbs because of different paths of "not found pages"
  const areBreadcrumbsDisplayed = true;
  const crumbs = [{ pathname: actualLang.path }, { pathname, crumbLabel: pageCrumbName }];
  const breadcrumbs = createBreadcrumbs(crumbs, areBreadcrumbsDisplayed, nodes, pathname);

  return (
    <Layout langProps={{ urls, lang: actualLang.iso }} breadcrumbs={breadcrumbs} seo={seo}>
      <SimpleBanner {...mainBanner} />

      {pageDescription?.description ? (
        <Row>
          <Col lg={{ span: 12, offset: 0 }}>
            <PageDescription {...pageDescription} />
          </Col>

          <Col lg={{ span: 10, offset: 1 }}>
            <hr className="horizontal-line" />
          </Col>
        </Row>
      ) : null}

      <Row>
        <Col lg={{ span: 6, offset: 3 }} className="simple-search__wrapper">
          <h3 className="simple-search__title">{searchTitle}</h3>
          <SimpleSearch {...{ langProps: { lang: actualLang.iso, urls: [] } }} />
        </Col>

        <Col lg={{ span: 6, offset: 3 }}>
          <TextBox {...textbox} />
        </Col>
      </Row>

      {bottomTeaser ? <Teaser {...bottomTeaser} /> : null}
    </Layout>
  );
};

export default NotFoundPage;
